<template>
  <div class="">
    <the-header-alt title="Журнал здоровья" />
    <the-main>
      <timesheet-legend v-if="$route.params.classId" />

      <time-sheet v-if="$route.params.classId" />

      <core-container>
        <core-info
          type="info"
          icon="mdi-alert-circle-outline"
          v-if="!$route.params.classId"
        >
          <p>Для отображения журнала здоровья выберите соответствующий класс</p>
        </core-info>
      </core-container>
    </the-main>

    <!--    <the-footer></the-footer>-->
  </div>
</template>

<script>
// import datepicker from "pioneer-datepicker";
import TimesheetLegend from "../components/Legend.vue";
import TimeSheet from "../components/TimeSheet.vue";
import { defineComponent, onMounted } from "vue";
import CoreInfo from "@/app/shared/core/CoreInfo";
import CoreContainer from "@/app/shared/core/CoreContainer";
import { useItemTypesStore } from "@/app/modules/healths/store/itemType.store";

export default defineComponent({
  components: {
    CoreContainer,
    CoreInfo,
    TimesheetLegend,
    TimeSheet
  },

  setup() {
    const itemTypesStore = useItemTypesStore();
    onMounted(async () => {
      await itemTypesStore.loadItemTypes();
    });
  }
});
</script>
