import { ref } from "vue";
import { find, sortBy } from "lodash";

import UnitService from "@/app/modules/healths/services/unit.service";
import ItemTypeStyles from "@/app/modules/healths/constants/itemType.styles";

const itemTypes = ref([]);

export function useItemTypesStore() {
  const loadItemTypes = async () => {
    const unit = sessionStorage.getItem("unit");
    const items = await UnitService.getItemTypes(unit);
    itemTypes.value = sortBy(items, ["priority"]);
  };

  const getItemTypeStyle = id => {
    const itemType = find(itemTypes.value, ["id", id]);

    if (!itemType) return ItemTypeStyles.default;

    return ItemTypeStyles[itemType.name.toLowerCase()];
  };

  return {
    itemTypes,
    loadItemTypes,
    getItemTypeStyle
  };
}
