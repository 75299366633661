export default {
  default: "",
  present: "text-green-700 bg-green-200",
  unpresent: "text-white bg-red-400",

  "covid-19": "text-white bg-gray-600",
  "контакт covid-19": "text-white bg-pink-400",

  орви: "text-white bg-blue-400",
  грипп: "text-white bg-yellow-500",
  "др. болезни": "text-white bg-purple-400"
};
