<template>
  <div
    class="h-8 w-8 text-xs font-medium inline-flex cursor-pointer relative rounded items-center justify-center"
    :class="classes"
  >
    <slot></slot>

    <!--    <div class="tooltip" v-if="showTooltip">-->
    <!--      <p class="pb-2 border-b mb-2"><slot></slot> января 2021</p>-->
    <!--      <div class="flex items-center" v-if="type === 0">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-green-400"></div>-->
    <!--        <p>Присутствовал</p>-->
    <!--      </div>-->

    <!--      <div class="flex items-center" v-if="type === 1">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-red-400"></div>-->
    <!--        <p>Без причины</p>-->
    <!--      </div>-->

    <!--      <div class="flex items-center" v-if="type === 2">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-blue-400"></div>-->
    <!--        <p>ОРВИ</p>-->
    <!--      </div>-->

    <!--      <div class="flex items-center" v-if="type === 3">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-yellow-500"></div>-->
    <!--        <p>Грипп</p>-->
    <!--      </div>-->

    <!--      <div class="flex items-center" v-if="type === 4">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-purple-400"></div>-->
    <!--        <p>Контактный</p>-->
    <!--      </div>-->

    <!--      <div class="flex items-center" v-if="type === 5">-->
    <!--        <div class="h-4 mr-2 w-4 rounded bg-gray-600"></div>-->
    <!--        <p>COVID-19</p>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { isNull } from "lodash";

import { useItemTypesStore } from "@/app/modules/healths/store/itemType.store";
import ItemTypeStyles from "@/app/modules/healths/constants/itemType.styles";

export default defineComponent({
  props: {
    present: { type: Boolean, default: true },
    type: { type: [Number, null], default: null }
  },

  setup(props) {
    const showTooltip = ref(false);

    const itemTypeStore = useItemTypesStore();

    const classes = computed(() => {
      if (props.present) return ItemTypeStyles.present;

      if (!props.present && isNull(props.type)) {
        return ItemTypeStyles.unpresent;
      } else {
        return itemTypeStore.getItemTypeStyle(props.type);
      }
    });

    const open = () => {
      showTooltip.value = true;
    };

    const close = () => {
      showTooltip.value = false;
    };

    return {
      props,
      showTooltip,
      classes,
      open,
      close
    };
  }
});
</script>
