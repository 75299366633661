import { ref } from "vue";

const show = ref(false);
const learner = ref({});
const date = ref("");

export function useTimesheetModal() {
  const open = (l, d) => {
    show.value = true;
    learner.value = l;
    date.value = d;
  };

  const close = () => {
    show.value = false;
    learner.value = {};
    date.value = "";
  };

  return {
    show,
    learner,
    date,
    open,
    close
  };
}
