<template>
  <div class="flex relative">
    <core-button @click="openPicker" class="font-medium">
      {{ months[currentMonth] }} | {{ currentYear }}
    </core-button>

    <div
      v-if="showPicker"
      @click.self="closePicker"
      class="z-30 inset-0 fixed bg-black bg-opacity-40"
    ></div>

    <div v-if="showPicker" class="picker">
      <div class="p-4 border-b grid grid-cols-2 gap-4">
        <p class="text-sm font-medium">Выберите дату</p>
        <!--        <core-button class="text-center font-medium">-->
        <!--          {{ months[currentMonth] }}-->
        <!--        </core-button>-->
        <!--        <core-button class="text-center font-medium">-->
        <!--          {{ currentYear }}-->
        <!--        </core-button>-->
      </div>

      <div class="grid grid-cols-2 mb-2 border-b">
        <div class="border-r">
          <div
            v-for="(month, index) in months"
            :key="index"
            @click="setMonth(index)"
            class="cursor-pointer text-sm px-4 font-medium flex items-center justify-center h-8 hover:bg-gray-100"
            :class="currentMonth === index && 'bg-gray-300 hover:bg-gray-300'"
          >
            {{ month }}
          </div>
        </div>

        <div class="year">
          <div
            v-for="year in years"
            :key="year"
            @click="setYear(year)"
            class="cursor-pointer text-sm px-4 font-medium flex items-center justify-center h-8 hover:bg-gray-100"
            :class="currentYear === year && 'bg-gray-300 hover:bg-gray-300'"
          >
            {{ year }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref } from "vue";

import CoreButton from "@/app/shared/core/CoreButton";

export default defineComponent({
  components: { CoreButton },

  props: {
    date: { type: String, required: true }
  },

  emits: ["update:date"],

  setup(props, { emit }) {
    const months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь"
    ];

    const years = [
      2016,
      2017,
      2018,
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027
    ];

    const currentMonth = ref(0);
    const currentYear = ref(2021);

    const showPicker = ref(false);

    watch(
      () => props.date,
      () => {
        currentMonth.value = Number(props.date.slice(5, 7)) - 1;
        currentYear.value = Number(props.date.slice(0, 4));
      }
    );

    const formatDate = () => {
      const monthString = (currentMonth.value + 1).toString().padStart(2, "0");
      return `${currentYear.value}-${monthString}-01`;
    };

    const openPicker = () => {
      showPicker.value = true;
      document.body.style.overflow = "hidden";
    };
    const closePicker = () => {
      showPicker.value = false;
      document.body.style.overflow = "auto";
    };

    const setMonth = month => {
      currentMonth.value = month;
      emit("update:date", formatDate());
      closePicker();
    };
    const setYear = year => {
      currentYear.value = year;
      emit("update:date", formatDate());
      closePicker();
    };

    return {
      months,
      years,
      currentMonth,
      currentYear,

      showPicker,
      openPicker,
      closePicker,

      setYear,
      setMonth
    };
  }
});
</script>

<style scoped>
.picker {
  @apply z-30 fixed bottom-0 right-0 bg-white shadow-md rounded;
  @apply md:w-64 md:absolute md:right-0 md:top-0 md:bottom-auto;
}
</style>
