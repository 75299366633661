<template>
  <div
    class="gg border-b pb-4 mb-4 md:mb-8 sticky top-0 bg-white pt-2 md:pt-4 relative z-30"
  >
    <core-container>
      <h3 class="text-2xl font-bold text-gray-700 mb-4">Легенда</h3>
      <div class="flex py-2 overflow-x-auto min-w-0">
        <div class="flex whitespace-nowrap items-center pr-4 mr-4 border-r">
          <div class="w-4 h-4 rounded mr-2 bg-green-400"></div>
          <p class="text-xs md:text-sm font-medium text-gray-500">
            Присутствует
          </p>
        </div>

        <div class="flex whitespace-nowrap items-center pr-4 mr-4 border-r">
          <div class="w-4 h-4 rounded mr-2 bg-red-400"></div>
          <p class="text-xs md:text-sm font-medium text-gray-500">
            Без причины
          </p>
        </div>

        <div
          v-for="item in itemTypes"
          :key="item.id"
          class="flex whitespace-nowrap items-center pr-4 mr-4 border-r"
        >
          <div class="w-4 h-4 rounded mr-2" :class="getClasses(item.id)"></div>
          <p class="text-xs md:text-sm font-medium text-gray-500">
            {{ item.name }}
          </p>
        </div>
      </div>
    </core-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useItemTypesStore } from "@/app/modules/healths/store/itemType.store";

export default defineComponent({
  setup() {
    const itemTypesStore = useItemTypesStore();
    const getClasses = id => itemTypesStore.getItemTypeStyle(id);

    return {
      itemTypes: itemTypesStore.itemTypes,
      getClasses
    };
  }
});
</script>
