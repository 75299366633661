<template>
  <div>
    <core-container>
      <div class="flex justify-between">
        <h3 class="text-2xl font-bold text-gray-700 mb-6">
          Журнал
        </h3>

        <date-select v-model:date="currentDate.date"></date-select>
      </div>

      <core-loading v-if="timesheet.loading.value" />

      <div class="flex" v-show="!timesheet.loading.value || false">
        <core-table class="border-r">
          <core-table-body>
            <template
              v-for="(tm, index) in timesheet.timesheet.value"
              :key="index"
            >
              <core-table-row v-if="index % 10 === 0">
                <core-table-cell
                  class="text-xs text-gray-500 hidden md:table-cell"
                  isFirst
                >
                  #
                </core-table-cell>
                <core-table-cell
                  class=" text-xs font-medium text-gray-500 h-8 sticky left-0"
                >
                  ФИО
                </core-table-cell>
              </core-table-row>

              <core-table-row>
                <core-table-cell isFirst class="hidden md:table-cell">
                  {{ index + 1 }}
                </core-table-cell>
                <core-table-cell class="sticky left-0 max-w-xs truncate pr-4">
                  <div class="h-8 flex items-center text-xs md:text-sm">
                    {{ tm.surname }} {{ tm.name }}
                  </div>
                </core-table-cell>
              </core-table-row>
            </template>
          </core-table-body>
        </core-table>

        <div ref="container" class="overflow-x-auto">
          <core-table>
            <core-table-body>
              <template
                v-for="(learner, index) in timesheet.timesheet.value"
                :key="index"
              >
                <core-table-row v-if="index % 10 === 0">
                  <core-table-cell
                    class="text-center font-medium text-xs text-gray-500"
                    v-for="(value, key) in learner.timeSheet"
                    :key="key"
                  >
                    {{ key.slice(-2) }}
                  </core-table-cell>
                </core-table-row>

                <core-table-row>
                  <core-table-cell
                    v-for="(value, key) in learner.timeSheet"
                    :key="key"
                  >
                    <free-day v-if="timesheet.isFreeDay(key)" />
                    <empty-day v-else-if="!!!value" />
                    <helath-type
                      v-else
                      :present="value && value.present"
                      :type="value && value.timeSheetItemTypeId"
                      @click="openModal(learner, key)"
                    >
                      {{ key.slice(-2) }}
                    </helath-type>
                  </core-table-cell>
                </core-table-row>
              </template>
            </core-table-body>
          </core-table>
        </div>

        <modal-item-types
          v-if="timesheetModal.show.value"
          @close="timesheetModal.close"
        />
      </div>
    </core-container>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import UnitService from "@/app/modules/healths/services/unit.service";

import { useTimesheetStore } from "@/app/modules/healths/store/timesheet.store";
import { useTimesheetModal } from "@/app/modules/healths/composables/useTimesheetModal";

import HelathType from "@/app/modules/healths/components/HelathType.vue";
import CoreContainer from "@/app/shared/core/CoreContainer";
import FreeDay from "@/app/modules/healths/components/FreeDay";
import EmptyDay from "@/app/modules/healths/components/EmptyDay";
import ModalItemTypes from "@/app/modules/healths/components/ModalItemTypes";
import CoreTableBody from "@/app/shared/core/Table/CoreTableBody";
import CoreTableCell from "@/app/shared/core/Table/CoreTableCell";
import DateSelect from "@/app/modules/healths/components/DateSelect";
import CoreLoading from "@/app/shared/core/CoreLoading";

export default defineComponent({
  components: {
    CoreLoading,
    DateSelect,
    CoreTableCell,
    CoreTableBody,
    ModalItemTypes,
    EmptyDay,
    FreeDay,
    CoreContainer,
    HelathType
  },

  setup() {
    const timesheet = useTimesheetStore();
    const timesheetModal = useTimesheetModal();
    const route = useRoute();

    const container = ref(null);
    const selected = ref("");
    const days = ref([...Array(31).keys()]);

    const date = ref(new Date());

    const currentDate = ref({});

    watch(
      () => currentDate.value.date,
      async () => {
        await timesheet.loadTimesheet(
          route.params.classId,
          currentDate.value.date
        );
      }
    );

    watch(
      () => route.params.classId,
      async () =>
        await timesheet.loadTimesheet(
          route.params.classId,
          currentDate.value.date
        )
    );

    onMounted(async () => {
      currentDate.value = await UnitService.getDate(
        sessionStorage.getItem("unit")
      );
      container.value.addEventListener("wheel", horizontalScroll);
    });

    const getRandomType = (exit = false) => {
      if (exit) return -1;
      const rand = Math.random();

      return rand < 0.9 ? 0 : Math.floor(Math.random() * 5) + 1;
    };

    const openModal = (learner, date) => {
      timesheetModal.open(learner, date);
    };

    const horizontalScroll = e => {
      e.preventDefault();

      if (e.deltaY > 0) {
        container.value.scroll({
          left: container.value.scrollLeft + 48
        });
      } else {
        container.value.scroll({
          left: container.value.scrollLeft - 48
        });
      }
    };

    return {
      timesheet,
      container,
      days,
      date,
      getRandomType,

      currentDate,

      timesheetModal,
      selected,
      openModal
    };
  }
});
// import Api from "@/app/services/Api";

// // import ModalConfirm from "./ModalConfirm.vue";

// import { sortBy } from "lodash";
// // import HelathType from "@/app/modules/healths/components/HelathType.vue";

// export default {
//   components: {
//     // ModalConfirm,
//     // HelathType
//   },

//   data() {
//     return {
//       days: [...Array(31).keys()],
//       learners: [],

//       selected: "",
//       modalShow: false,

//       pos: {
//         x: 0,
//         y: 0
//       }
//     };
//   },

//   async mounted() {
//     const { data: learners } = await Api.classes.getClassLearners(8);

//     this.learners = sortBy(learners, ["surname", "name"]);

//     this.$refs.container.addEventListener("wheel", e => {
//       e.preventDefault();
//       if (e.deltaY > 0) {
//         this.$refs.container.scroll({
//           left: this.$refs.container.scrollLeft + 48
//         });
//       } else {
//         this.$refs.container.scroll({
//           left: this.$refs.container.scrollLeft - 48
//         });
//       }
//     });
//   },

//   methods: {
//     getRandomType(exit = false) {
//       if (exit) return -1;
//       return Math.floor(Math.random() * 6);
//     },
//     openModal(learner) {
//       this.selected = `${learner.surname} ${learner.name}`;
//       this.modalShow = true;
//     },

//     closeModal() {
//       this.selected = "";
//       this.modalShow = false;
//     },

//     hover(row, col) {
//       this.pos = { row, col };
//     }
//   }
// };
</script>
