<template>
  <modal-confirm
    :actions="false"
    title="Изменение статуса"
    @close="$emit('close')"
  >
    <div class="flex justify-between items-center mb-4 pb-2 border-b">
      <p class="font-medium text-gray-700">
        {{ selected }}
      </p>

      <p class="text-sm font-medium text-gray-500">
        {{ timesheetModal.date.value }}
      </p>
    </div>

    <div class="grid grid-cols-2 gap-4 mb-4 pb-4 border-b">
      <div
        class="cursor-pointer flex justify-center h-10 items-center rounded text-sm font-medium text-green-700 bg-green-200"
        @click="updateTimeSheet(null, true)"
      >
        Присутствует
      </div>

      <div
        class="cursor-pointer flex justify-center h-10 items-center rounded text-sm font-medium text-white bg-red-400"
        @click="updateTimeSheet(null, false)"
      >
        Без причины
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="item in itemTypes"
        :key="item.id"
        class="cursor-pointer flex justify-center h-10 items-center rounded text-sm font-medium text-white"
        @click="updateTimeSheet(item.id, false)"
        :class="getClasses(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
  </modal-confirm>
</template>

<script>
import { computed, defineComponent } from "vue";

import { useTimesheetModal } from "@/app/modules/healths/composables/useTimesheetModal";
import { useItemTypesStore } from "@/app/modules/healths/store/itemType.store";

import ModalConfirm from "@/app/shared/components/ModalConfirm";
import { useTimesheetStore } from "@/app/modules/healths/store/timesheet.store";

export default defineComponent({
  components: { ModalConfirm },

  emits: ["close"],

  setup(props, { emit }) {
    const timesheetModal = useTimesheetModal();
    const timesheet = useTimesheetStore();
    const itemTypesStore = useItemTypesStore();

    const selected = computed(() => {
      return `${timesheetModal.learner.value.surname} ${timesheetModal.learner.value.name}`;
    });

    const getClasses = id => {
      return itemTypesStore.getItemTypeStyle(id);
    };

    const updateTimeSheet = async (timeSheetItemTypeId, present) => {
      await timesheet.updateTimeSheet(timesheetModal.date.value, {
        id: timesheetModal.learner.value.id,
        timeSheetItemTypeId,
        present
      });

      emit("close");
    };

    return {
      selected,
      timesheetModal,
      itemTypes: itemTypesStore.itemTypes,
      getClasses,
      updateTimeSheet
    };
  }
});
</script>

<style scoped></style>
