import { ref, toRefs } from "vue";

import UnitService from "@/app/modules/healths/services/unit.service";
import { find, includes, sortBy } from "lodash";

const state = ref({
  timesheet: [],
  freeDays: [],
  loading: false,
  error: false
});

export function useTimesheetStore() {
  const loadTimesheet = async (categoryId, date) => {
    state.value.timesheet = [];

    console.log(categoryId, date);

    try {
      state.value.loading = true;
      state.value.error = false;

      const timesheet = await UnitService.getTimeSheet(categoryId, date);

      state.value.timesheet = sortBy(timesheet.items, ["surname", "name"]);
      state.value.freeDays = timesheet.freeDays;
    } catch (e) {
      state.value.error = e;
    } finally {
      state.value.loading = false;
    }
  };

  const updateTimeSheet = async (
    date,
    { id, timeSheetItemTypeId, present }
  ) => {
    try {
      await UnitService.updateTimeSheet(date, {
        id,
        timeSheetItemTypeId,
        present
      });

      const learnerTimesheet = find(state.value.timesheet, ["id", id]);
      learnerTimesheet.timeSheet[date] = {
        timeSheetItemTypeId,
        present
      };
    } catch (e) {
      console.log(e);
    }
  };

  const isFreeDay = dateString => {
    return includes(state.value.freeDays, dateString);
  };

  return {
    ...toRefs(state.value),
    loadTimesheet,
    updateTimeSheet,
    isFreeDay
  };
}
